<template>
    <div v-if="showModal" :class="['modal','modal-feedback',showModal?'modal-open':'']" style="display: block" @keyup.esc="onClose">
        <div class="modal-dialog" role="document">
            <div class="modal-content">

                <div class="modal-header">
                    <button tabindex="0" type="button" class="close" ref="closeBtn" data-dismiss="modal" aria-label="Close" @click="onClose">
                        <span class="material-icons" aria-hidden="true">close</span>
                    </button>
                </div>

                <h3 class="modal-content__title text-center">Du hast Feedback oder möchtest uns direkt kontaktieren?</h3>
                <p class="modal-content__info text-center">Dann schreibe uns gerne direkt eine Nachricht. Du kannst deine Nachricht anonym an uns senden oder deine Email angeben, damit wir dir antworten können.</p>
                <div class="feedback">
                    <form action="#!" class="mt30">
                        <div class="form-group mb15">
                            <input type="text" class="form-control" ref="firstFeedbackInput" v-model="data.sender" placeholder="Deine Email Adresse (optional)">
                            <div class="email-help help" v-show="errors.sender">Geben Sie eine gültige E-Mail Adresse an</div>
                        </div>
                        <div class="form-group mb15">
                            <textarea class="form-control" v-model="data.message" cols="30" rows="10" placeholder="Deine Nachricht"></textarea>
                            <div class="message-help help" v-show="errors.message">Geben Sie eine Nachricht ein</div>
                        </div>

                        <checkbox labelText='Ich erkläre mein Einverständnis in die Verarbeitung meiner personenbezogenen Daten. Diese Einwilligung erteile ich freiwillig. 
                        Hinweis: Sie können diese Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Weitere Informationen entnehmen Sie bitte den <a href="/system/17559" target="_blank">Informationspflichten</a>.' errorMessage="Die Nachricht kann nur gesendet werden wenn Sie Ihr Einverständnis zur Verarbeitung Ihrer personenbezogenen Daten erteilen." :showError="errors.datenschutz" name="privacy-policy" @checkBoxChanged='checkBoxChanged'  />

                        <button class="btn btn-block btn-secondary mt30" type="submit" @click="sendMessage">Nachricht senden</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { sendMail } from '@/api/email';
    import { hasErrors } from '@/utils/helpers';
    import { validEmail } from '@/utils/validate';

    export default {
        name: "Feedback",
        components: {
            Checkbox: () => import('@/components/controls/Checkbox.vue'),
        },
        props: {
            showModal: {
                type: Boolean,
            default: false,
            },
        },
        data() {
            return {
                data: {
                    email_id: 18,
                    message: '',
                    datenschutz: false,
                    sender: "",
                    app: this.$appName,
                    policies: false,
                },
                errors: {
                    datenschutz: false,
                    email: false,
                    message: false
                }
            }
        },
        watch: {
            showModal(newValue){
              this.$nextTick(() => {
                if(newValue == true){
                    this.$refs.closeBtn.focus();
                }
            });
              if(newValue){
                document.body.classList.add('prevent-scrolling');
            }
            else{
              document.body.classList.remove('prevent-scrolling');  
          }
      },
},
methods: {
    onClose() {
        this.$emit('on-close');
    },
    checkBoxChanged(value){
        this.data.datenschutz = value;
    },
    sendMessage: function (event){
        event.preventDefault();

        if(this.data.sender != "" && !validEmail(this.data.sender)){
            this.errors.sender = true;
        }
        else{
            this.errors.sender = false;
        }

        if(this.data.message==""){
            this.errors.message = true;
        }
        else{
            this.errors.message = false;
        }

        if(this.data.datenschutz==false){
            this.errors.datenschutz = true;
            this.data.policies = false;
        }
        else{
            this.data.policies = true;
            this.errors.datenschutz = false;
        }

        if(!hasErrors(this.errors)){
            sendMail(this.data).then( response => {
                this.$notify({
                  duration: 3500,
                  title: 'E-Mail gesendet',
                  text: 'Ihre E-Mail wurde versendet!'
              });

                this.data.message = '';
                this.data.sender = '';
                this.data.datenschutz = false;
                this.onClose();

            }).catch(() => {
                this.$notify({
                    type: 'error',
                    duration: 3500,
                    title: 'Fehler!',
                    text: 'Es ist ein Fehler aufgetreten. Ihre E-Mail konnte nicht gesendet werden.'
                });
            });
        }

    },
}
};
</script>
<style lang="scss" scoped>

    .modal-feedback{
      min-height: 100vh;
      min-height: -webkit-fill-available;
      overflow-y: scroll;
  }

  .modal-content{
    height: -webkit-fill-available;
}

.form-group, .form-check{
    display: block;
}

.form-control{
    display: block;
}

.label{
    width: auto !important;
}

.help{
    color: red;
    display: block;
    margin-top: 2px;
}




.modal {
    @media(max-width:991px) {
      .modal-dialog {
        max-width: 620px;
    }
}

.modal-content {
    padding: 45px 30px;

    @media(max-width:992px){
        padding: 30px 20px;
    }

    .close {
      margin: -3rem -3rem -3rem auto;
  }
}
}

.form-control {
    border: 1px solid rgba($color: #3A3A3A, $alpha: 0.10) !important;

}


</style>